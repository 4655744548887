import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import axios from 'axios';
import { apiUrl} from'../Config/Config';


	
class Add extends Component {

	constructor(props)
	{
		// CheckAuth();
		super();	
		this.state = {
			name:'',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'active',
			read_only:true,
			full_control: false,
			modulelist:'',
			checkedState: [],
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleChange = this.handleChange.bind(this);		
    }
	componentDidMount() {			
		var qs = require('qs');		
		 
		var postObject = {
			admin_id: localStorage.getItem('admin_id'),
	   };	 
		axios.post(apiUrl+"Rolesmanagement/getcompanymodules_list",qs.stringify(postObject)).then(resp => {   
			if(resp.data.status == 'success'){ 
				
				this.setState({modulelist:resp.data.modulelist});				
			}
		});
	}	  
	handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
   	};
   	handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });

		if(name === 'access_control'){
			this.setState({full_control: !this.state.full_control});
			this.setState({read_only: !this.state.read_only});
		 }

  }


  handleSubmit = () => {
	if(this.validateForm()){
		const formdata = this.state;
		var qs = require('qs');
		var status = '';

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		if(formdata.selectedvalue === ''){
		status =formdata.selectedOption.value;
		}else{
		status = formdata.selectedvalue;
		}
		if(formdata.read_only){
			var control = 'readonly';
		}else{
			var control = 'grant';
		}
		var postObject ={
			admin_id: localStorage.getItem('admin_id'),
			name : formdata.name,
			subadmin_privilege: control,
			status: status,
			selected_module:formdata.checkedState,
		}
	
		axios.post(apiUrl+"Rolesmanagement/add",qs.stringify(postObject)).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html(this.props.history.push('/rolemanagement'));
					}
					.bind(this),
					3000
					);
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
	}

	validateForm() {
		const {name,checkedState} = this.state;
	
		let errors = {};
		  let formIsValid = true;
	
		
		if(!name){
			formIsValid = false;
			$('.errorcat_name').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errorcat_name').html('');
		}

		if(checkedState.length<1){
			formIsValid = false;
			$('.errormodule').html('<span class="errorspan">Please fill the field</span>');
		}else{
			
			$('.errormodule').html('');
		}
		
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	
	handleOnChange = (i,event) => {
        var id = event.target.value;
        var idArr = [...this.state.checkedState];
     
        const index = idArr.findIndex(list => list === id);
        if(index > -1) {

        idArr = [...idArr.slice(0, index), ...idArr.slice(index + 1)]
        } else {
        idArr.push(id);
        }
      
        
        this.setState({checkedState: idArr});

     };

  render() {

	const {selectedOption} = this.state;


    return (
      <div className="wrapper"> 
 
	  <Header />
      <MenuSidebar currentpage="rolemanagement" />  

	<div id="location-error"></div>
	<div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a className="cursor-pointer btn-arrow" href="/rolemanagement">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
				</a>
			</div>
			<div className="title">
				<h4>Add Role</h4>				
			</div>
			 <form className="edit"  onSubmit={ e => { this.handleSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Role Name<span class="required" style={{ color: "red" }} > * </span> : </label>
				    <input type="text" name="name" onChange={this.handleInputChange} className="form-control" value={this.state.name}/>
					<div className="errorcat_name"></div>
				</div>
				<div className="form-group">
					<label>Grant SubAdmin Privileges
					: </label><br />
					<div className='d-flex align-items-center'>
					<input onChange={this.handleInputChange} type="radio" name="access_control" checked={this.state.read_only}/>
					<span>Read Only</span>
						<input onChange={this.handleInputChange} type="radio"  name="access_control" checked={this.state.full_control}/>
					<span>Grant Full Control (Add, Edit, Delete, View) </span>
					</div>
 				</div> 
				 <div className="form-group mt-5">
				   		<h3>Modules Permission <span class="required" style={{ color: "red" }} > * </span>:</h3>
						{this.state.modulelist && Object.keys(this.state.modulelist).length > 0 && this.state.modulelist.map(({label, value}, index) => {
						return (<> 
							<div className="custom_checkbox d-flex align-items-center">
							<input
							type="checkbox"
							id={`custom-checkbox-${index}`}
							name={label}
							value={value}
							checked={this.state.checkedState.includes(value)}
							onChange={this.handleOnChange.bind(this,index)}
							/>
							<span></span>
							<label htmlFor={`custom-checkbox-${index}`}>{label}</label>
							</div>
						</>)})}	
						<div className="errormodule"></div>
					</div>	
				
			</div>
                  
			<div className="form-right">

				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} > * </span> :</label>
						   <Select 
						   name="status"
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
							isSearchable = {false}
                            onChange={this.handleChange}
                            />
	           </div> 
			  
             

			</div>	
			</div>		
			<div className="btn-group export">	
				<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" >
									{/* <span className="load-	data"></span>  */}
									Submit
			   </button>
			 
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default (withRouter(Add));